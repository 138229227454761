body {
    background-color: #FFFFFF;
}

.BaseHeader {
    background-color: #60A2AB;
    position: relative;
    overflow: hidden;
    display: flex;
    background-size: cover;
    background-position: 50%;
    border-radius: 0.75rem;
    color: #fff!important;
}

.Row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
}

.Card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}

.BaseHeader .container {
    z-index: 1;
}

a.Logo {
    padding-top: 0.40625rem;
    padding-bottom: 0.40625rem;
    margin-right: 1rem;
    font-size: 1.125rem;
    white-space: nowrap;
}

.MainListGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.5rem;
    width: 92.5%!important;
}

.MainListItem {
    background-color: #f8f9fa;
    border-radius: 0.75rem;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    border-width: 0;
    border: 0;
    display: flex;
    position: relative;
    padding: 0.5rem 1rem;
    color: inherit;
}

.MainListItem:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.LicenseSection {
    display: flex;
    flex-direction: column;
}

/* .LicenseSection a {
    color: black;
}

.LicenseSection a:hover {
    color:#60A2AB;
} */

.Card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
}

.LicAttributeList {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.5rem;
}

.LicAttributeItem {
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    color: inherit;
}

.LoaderBox {
    display: flex;
    z-index: 1000;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #f8f9fa90;
}

.LoaderLogo {
    position: fixed;
    bottom: 10px;
    left: 10px;
    animation: LoaderLogo 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.LoaderLogo > img {
    width: 70px;
}

@keyframes LoaderLogo {
    0%, 100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(3600deg);
    } 
}

a {
    text-decoration: none!important;
}